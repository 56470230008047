<template>
  <div class="page-container">
    <PageHeader icon="reports-active.svg" title="Отчеты" />
    <div class="page-content">
      <tabs
        v-if="tabs && tabs.length > 0"
        :tabsNames="tabs"
        @firstTabActive="firstTabActive = $event"
      />
      <div
        class="tab-content table"
        :class="{ 'tab-content__active-first': firstTabActive }"
        :style="{ marginTop: tabs && tabs.length > 0 ? '0' : '30px' }"
      >
        <TableComponent
          :noData="noData"
          :columns="columns"
          :rowsObj="tableRows"
          :title="'Акт сверки'"
          :isBordered="true"
          @downloadXSLX="downloadDebtReport"
          :actionType="'reconciliationReportActions'"
          :isLoading="loading"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import Tabs from '@/components/tabs.vue'
  import { mapActions, mapMutations, mapState } from 'vuex'

  export default {
    name: 'ReconciliationReport',
    components: {
      Tabs
    },
    data() {
      return {
        firstTabActive: false,
        tabsNames: [
          { name: 'Транзакционный отчет', linkName: 'reports.transactions' },
          { name: 'Платежный отчет', linkName: 'reports.payment' },
          { name: 'Отчет по оборотам карт', linkName: 'reports.cards' },
          { name: 'Зарплатный отчет', linkName: 'reports.salary' },
          { name: 'Отчет Сверка', linkName: 'reports.revise' },
          { name: 'Отчет по задолженностям', linkName: 'reports.debt' },
          { name: 'Акт сверки', linkName: 'reports.reconciliation' }
        ],
        tableColumns: [
          {
            name: 'Дата',
            field: 'dates',
          },
          {
            name: 'Документ',
            field: 'type',
          },
          {
            name: 'Дебет',
            field: 'debet',
          },
          {
            name: 'Кредит',
            field: 'credit',
          }
        ],
        tableRows: {},
        noData: false,
        loading: false,
        totalCount: 0,
        pageSize: 0,
        pageCount: 0,
        currentPage: 0
      }
    },
    computed: {
      ...mapState({
        token: (state) => state.user.token,
        userRole: (state) => state.user.userRole,
        columns: (state) => state.popups.columns,
        reconciliationReport: (state) => state.admin.reconciliationReport
      }),
      tabs() {
        switch (this.userRole) {
          case 3:
            return []
          case 2:
            return [this.tabsNames[0], this.tabsNames[2], this.tabsNames[3]]
          case 1:
            return [
              this.tabsNames[0],
              this.tabsNames[4],
              this.tabsNames[3],
              this.tabsNames[2],
              this.tabsNames[5],
              this.tabsNames[6]
            ]
        }
      }
    },
    watch: {
      async reconciliationReport(val) {
        await this.setTablesData()
      }
    },
    methods: {
      ...mapActions({
        getReconciliationReport: 'getReconciliationReport'
      }),
      ...mapMutations({
        setColumns: 'SET_COLUMNS',
        setPaginationFilters: 'SET_PAGINATION_FILTERS',
        resetPaginationFilters: 'RESET_PAGINATION_FILTERS'
      }),
      async downloadDebtReport() {
        await this.getReconciliationReport(true)
      },
      async setTablesData() {
        this.tableRows = this.reconciliationReport || {}
        this.noData = !this.tableRows || !this.tableRows.start_saldo
        this.loading = false
      }
    },
    async mounted() {
      this.setColumns(null)
      this.resetPaginationFilters()
      this.setColumns(this.tableColumns)
      this.noData = true;

      // await this.getReconciliationReport({
      //   startDate: '2024-06-01',
      //   endDate: '2024-12-05',
      //   customerId: 7,
      //   getFile: false
      // }).catch(() => {
      //   this.noData = true
      // })
    }
  }
</script>

<style scoped lang="scss">
  @import '../../styles/variables';
  .separator {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    height: 1px;
    background: $back;
    width: 100%;
  }

  .margin {
    margin-top: 30px;
  }
</style>
