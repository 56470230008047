<template>
  <div class="popup wide">
    <h4 class="popup__title middle">Установить скидки для ВИНКов</h4>

    <div class="modal-remove" v-if="removeDate !== null">
      <div class="modal-remove__overlay" @click="removeDate = null"></div>
      <div class="modal-remove__content">
        <p>Вы действительно хотите удалить скидки за {{ removeDate }}?</p>

        <div class="actions">
          <button class="button btn-primary" @click="removeDate = null">Нет</button>
          <button class="button btn-primary" @click="setRemove()">Да</button>
        </div>
      </div>
    </div>

    <div class="discount-block">
      <ul>
        <li>
          <button class="btn-primary export-btn" @click="setNew = true;setNewDate()">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.25 7H1.75" stroke="#6DB534" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7 1.75V12.25" stroke="#6DB534" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            Добавить дату
          </button>
        </li>
        <li v-for="(date, index) of datesList">
          <button class="discount-block__btn-date" @click="setItem(date, index);setNew = false" :class="{ 'active-date': date.active, 'restricted-date': date.arr.some(item => item.restricted) }">
            {{ date.name }}
            <button :disabled="date.arr.some(item => item.restricted)" @click.stop="removeItem(date)">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.42857 5.25V3.6C6.42857 3.24196 6.56403 2.89858 6.80515 2.64541C7.04627 2.39223 7.37329 2.25 7.71429 2.25H10.2857C10.6267 2.25 10.9537 2.39223 11.1949 2.64541C11.436 2.89858 11.5714 3.24196 11.5714 3.6V5.25M14.25 5.25L13.5 14.4C13.5 14.758 13.3645 15.1014 13.1234 15.3546C12.8823 15.6078 12.5553 15.75 12.2143 15.75H5.78571C5.44472 15.75 5.1177 15.6078 4.87658 15.3546C4.63546 15.1014 4.5 14.758 4.5 14.4L3.75 5.25H14.25Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3 5.25H4.33333H15" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </button>
        </li>
      </ul>
      <div class="form">
        <div class="date-picker">
          <div class="wrap">
            <span class="date-picker-label">Выбора даты</span>

            <VueDatePicker
              ref="dateRange"
              v-model="date"
              :enable-time-picker="false"
              :format="'dd.MM.yyyy'"
              locale="ru"
              cancelText="Отмена"
              selectText="Выбрать"
              :clearable="false"
              :teleport="true"
              :disabled="!setNew"
              @update:model-value="formatingDate('surety')"
            >
              <template #input-icon>
                <img class="input-slot-image" src="../../assets/datepicker-icon.svg" alt="d"/>
              </template>
            </VueDatePicker>
          </div>
        </div>
        <template v-for="(item, index) in providersList">
          <div class="form__item">
            <div class="label">
              {{ item.name }}
            </div>
            <div class="input">
              <input type="number" v-model="item.value" :disabled="disableEdit">
            </div>
            <button :disabled="disableEdit" class="percent-toggle" :class="{'active': item.is_not_percent === false}" @click="setItemPercent(index, 'is_not_percent', false)">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path d="M14.25 3.75L3.75 14.25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4.875 6.75C5.91053 6.75 6.75 5.91053 6.75 4.875C6.75 3.83947 5.91053 3 4.875 3C3.83947 3 3 3.83947 3 4.875C3 5.91053 3.83947 6.75 4.875 6.75Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.125 15C14.1605 15 15 14.1605 15 13.125C15 12.0895 14.1605 11.25 13.125 11.25C12.0895 11.25 11.25 12.0895 11.25 13.125C11.25 14.1605 12.0895 15 13.125 15Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
            <button :disabled="disableEdit" class="percent-toggle currency" :class="{'active': item.is_not_percent === true}" @click="setItemPercent(index, 'is_not_percent', true)">
              ₽
            </button>
          </div>
        </template>
<!--        <div class="left">-->
<!--          <template v-for="(item, index) in providersList">-->
<!--            <div class="form__item" v-if="index < providersList.length / 2">-->
<!--              <div class="label">-->
<!--                {{ item.name }}-->
<!--              </div>-->
<!--              <div class="input">-->
<!--                <input type="number" v-model="item.value">-->
<!--              </div>-->
<!--            </div>-->
<!--          </template>-->
<!--        </div>-->
<!--        <div class="right">-->
<!--          <template v-for="(item, index) in providersList">-->
<!--            <div class="form__item" v-if="index >= providersList.length / 2">-->
<!--              <div class="label">-->
<!--                {{ item.name }}-->
<!--              </div>-->
<!--              <div class="input">-->
<!--                <input type="number" v-model="item.value">-->
<!--              </div>-->
<!--            </div>-->
<!--          </template>-->
<!--        </div>-->
      </div>
    </div>

    <div class="actions">
      <button class="button btn-primary" @click="closeAll()">Отмена</button>
      <button :disabled="disableEdit" class="button btn-primary" @click="saveDiscount()">Сохранить</button>
    </div>
  </div>
</template>

<script>

import {popups} from '@/mixins/popups'
import {mapActions, mapState} from "vuex";
import VueDatePicker from "@vuepic/vue-datepicker";

export default {
  name: "setDiscount",
  components: {VueDatePicker},
  mixins: [popups],
  data: () => ({
    providersList: [],
    datesList: [],
    date: null,
    dateModel: null,
    setNew: true,
    removeDate: null
  }),
  computed: {
    ...mapState({
      fuelProviders: state => state.user.fuelProviders,
      clientProviders: state => state.customer.clientProviders,
      customer: state => state.popups.customer,
      discountBlockDay: (state) => state.customer.discountBlockDay,
    }),
    disableEdit() {
      return !this.setNew && this.datesList.find(date => date.active)?.arr.some(item => item.restricted)
    }
  },
  methods: {
    ...mapActions({
      getClientProviders: 'getClientProviders',
      setClientProviders: 'setClientProviders',
      removeClientProviders: 'removeClientProviders',
    }),
    setItemPercent(index, key, value) {
      this.providersList[index][key] = value
    },
    formatingDate(type) {
      this.dateModel = `${this.date.getFullYear()}-${String(this.date.getMonth() + 1).padStart(2, '0')}-${String(this.date.getDate()).padStart(2, '0')}`;
    },
    saveDiscount() {
      console.log('saveDiscount')
      let data = {
        start_date: this.dateModel,
        data: []
      };
      if (!this.setNew) {
        let dateSplit = this.date.split('.')
        data.start_date = `${dateSplit[0]}-${dateSplit[1]}-${dateSplit[2]}`;
      }
      this.providersList.forEach(item => {
        if (item.value !== null && item.value !== '') {
          data.data.push({
            fuel_provider_id: item.id,
            id: item.id,
            value: item.value,
            is_not_percent: item.is_not_percent
          })
        }
      })
      this.setClientProviders({customerID: this.customer.id, data})
    },
    setNewDate() {
      this.date = null;
      this.providersList.forEach(item => {
        item.value = '';
        item.is_not_percent = false;
      })
    },
    setItem(data, index) {
      this.datesList.forEach((item, i) => {
        item.active = index === i;
      })
      let dateSplit = data.name.split('.')
      this.date = `${dateSplit[2]}.${dateSplit[1]}.${dateSplit[0]}`;
      this.providersList.forEach(provider => {
        provider.value = '';
        provider.is_not_percent = false;
      })
      data.arr.forEach(item => {
        this.providersList.forEach(provider => {
          if (item.fuel_provider_id === provider.id) {
            provider.value = item.discount
            provider.is_not_percent = item.is_not_percent
          }
        })
      })
    },
    removeItem(date) {
      this.removeDate = date.name
    },
    setRemove() {
      let dateSplit = this.removeDate.split('.')
      let data = {
        customerID: this.customer.id,
        start_date: `${dateSplit[0]}-${dateSplit[1]}-${dateSplit[2]}`,
      }
      this.removeClientProviders(data).then(res => {
        this.setItems();
      });
      this.removeDate = null;
    },
    setItems() {
      this.getClientProviders(this.customer.id).then(res => {
        this.providersList = [];
        this.fuelProviders.forEach((item, index) => {
          this.providersList.push({
            id: item.id,
            name: item.name,
            value: '',
            is_not_percent: false
          })
        })

        const checkedClientProviders = this.checkRestrictions(this.clientProviders);

        let arr = [];
        Object.keys(checkedClientProviders).map((key) => {
          let keySplit = key.split('-')
          let date = `${keySplit[2]}.${keySplit[1]}.${keySplit[0]}`
          arr.push({
            name: date,
            arr: checkedClientProviders[key],
            active: false
          })
        })
        this.datesList = arr;
      })
    },
    checkRestrictions(providersData) {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();
      
      const prevMonth = currentMonth === 0 ? 11 : currentMonth - 1;
      const prevYear = currentMonth === 0 ? currentYear - 1 : currentYear;
      
      Object.keys(providersData).forEach(key => {
        providersData[key].forEach(item => {
          const itemDate = new Date(item.start_date);
          const itemMonth = itemDate.getMonth();
          const itemYear = itemDate.getFullYear();
          
          const isCurrentMonth = itemMonth === currentMonth && itemYear === currentYear;
          const isPrevMonth = itemMonth === prevMonth && itemYear === prevYear;
          
          const isBeforeBlockDay = currentDate.getDate() < this.discountBlockDay;
          
          item.restricted = !(isBeforeBlockDay && (isCurrentMonth || isPrevMonth));
        });
      });
      
      return providersData;
    }
  },
  mounted() {
    this.setItems();
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/variables";
.left, .right {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2px;
}

.wide {
  max-width: unset;
  width: auto;
  max-height: 80%;

  .form {
    flex-direction: unset;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 560px;
    max-height: calc(100vh - 400px);
    overflow-y: auto;
    overflow-x: hidden;
    gap: 6px;

    .form__item {
      width: 100%;
      gap: 0;
      .label {
        width: 200px;
      }
      .input {
        width: calc(100% - 80px);
        box-sizing: border-box;
        background: transparent;
        input {
          display: flex;
          padding: 10px;
          justify-content: space-between;
          align-items: center;
          flex: 1 0 0;
          align-self: stretch;
          padding-left: 55px;
          border-radius: 6px 0 0 6px;
          height: 40px;
          box-sizing: border-box;
          background: transparent;

          &:disabled {
            opacity: 0.7;
            cursor: not-allowed;
          }
        }
      }
      .percent-toggle {
        width: 38px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        border-radius: 0px;
        border: none;
        background: #F1F8EB;

        color: #6DB534;
        text-align: center;
        font-feature-settings: 'liga' off, 'clig' off;
        //font-family: "SF Pro Text";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 80% */

        &.currency {
          border-radius: 0px 6px 6px 0px;
        }

        svg {
          path {
            stroke: #6DB534;
          }
        }

        &.active {
          background: #6DB534;
          color: #fff;

          svg {
            path {
              stroke: #fff;
            }
          }
        }

        &:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }
      }
    }

    .left, .right {
      display: flex;
      flex-direction: column;
      width: 49%;
      gap: 2px;
    }
    @media screen and (max-width: 600px) {
      width: 100%;
      max-height: calc(80vh - 400px);
    }
  }
}

.discount-block {
  display: flex;

  & > ul {
    min-width: 170px;
    margin: 0 20px 0 0;
    padding: 0;
    list-style: none;

    li {
      width: 100%;
      margin-bottom: 5px;

      &:first-child {
        margin-bottom: 20px;

        button {
          justify-content: flex-start;
          svg {
            margin-right: 10px;
          }
        }
      }

      & > button {
        width: 100%;
      }
    }
  }

  &__btn-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #F1F8EB;
    background: none;
    text-align: left;
    transition: all ease .3s;

    &:hover {
      background: #F1F8EB;
    }

    &.active-date {
      background: #F1F8EB;
    }

    &.restricted-date {
      opacity: 0.7;
      filter: grayscale(1);
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      margin: 0;
      padding: 3px 4px 3px 3px;
      background: none;
      border: none;
      border-radius: 6px;
      transition: all ease .3s;

      &:hover {
        background: #85ff8e;
      }
      &:disabled {
        cursor: not-allowed;
      }
    }
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    width: 100%;
    & > ul {
      margin-right: 0;
    }
  }
}

.date-picker {
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
  align-self: stretch;

  .date-picker-label {
    display: flex;
    align-items: center;
    white-space: nowrap;
    min-width: 40%;
    padding: 0 10px;
  }


  .wrap {
    gap: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    color: $grey-2;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: SF_Pro_Medium, Arial, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 116.667% */
    text-align: left;

    .dp__input {
      border-radius: 6px;
      border: 1px solid $back !important;
      --dp-border-color: $back !important;
    }
  }
}

.modal-remove {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 11;

  &__overlay {
    width: 100%;
    height: 100%;
    background: $black;
    opacity: 0.3;
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    transition: none!important;
    transform: none!important;
  }

  &__content {
    position: relative;
    border-radius: 12px;
    background: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(109, 181, 52, 0.30);
    padding: 30px;
    z-index: 10;
  }
}
</style>
