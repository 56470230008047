<template>
  <div class="popup">
    <span v-if="loading" class="loader">
      <svg class="circular" height="50" width="50">
        <circle
          class="path"
          cx="25"
          cy="25.2"
          r="19.9"
          fill="none"
          stroke-width="6"
          stroke-miterlimit="10"
        />
      </svg>
    </span>
    <h4 class="popup__title middle">Дата блокировки изменения скидок/наценок</h4>
    <div v-if="date" class="form">
      <div class="form__item">
        <div class="label">Дата</div>
        <div class="input">
          <VueDatePicker
            v-model="date"
            :enable-time-picker="false"
            :disable-month-year-select="true"
            format="dd"
            locale="ru"
            cancelText="Отмена"
            selectText="Выбрать"
            class="only-day-picker"
            :clearable="false"
          />
        </div>
      </div>
    </div>

    <div class="actions">
      <button class="button btn-primary" @click="closeAll()">Отмена</button>
      <button :disabled="!date" class="button btn-primary" @click="saveDate()">Сохранить</button>
    </div>
  </div>
</template>

<script>
  import { popups } from '@/mixins/popups'
  import { mapActions, mapState } from 'vuex'
  import VueDatePicker from '@vuepic/vue-datepicker'

  export default {
    name: 'setDiscountBlockDay',
    components: { VueDatePicker },
    mixins: [popups],
    data: () => ({
      loading: false,
      date: null
    }),
    computed: {
      ...mapState({
        discountBlockDay: (state) => state.customer.discountBlockDay,
        showDiscountBlockDayPopup: (state) => state.popups.showDiscountBlockDayPopup
      })
    },
    methods: {
      ...mapActions({
        getDiscountBlockDay: 'getDiscountBlockDay',
        setDiscountBlockDay: 'setDiscountBlockDay'
      }),
      getRandomDateAsDateObject(day) {
        const currentYear = new Date().getFullYear() // Текущий год
        const validMonths = [] // Массив допустимых месяцев

        // Проверяем каждый месяц
        for (let month = 1; month <= 12; month++) {
          const date = new Date(currentYear, month - 1, day) // Создаем дату
          if (date.getDate() === day) {
            validMonths.push(month) // Добавляем месяц, если дата корректна
          }
        }

        // Если допустимых месяцев нет
        if (validMonths.length === 0) {
          throw new Error(`День ${day} недопустим для любого месяца.`)
        }

        // Выбираем случайный месяц из допустимых
        const randomMonth = validMonths[Math.floor(Math.random() * validMonths.length)]

        // Возвращаем объект Date
        return new Date(currentYear, randomMonth - 1, day)
      },
      async saveDate() {
        this.loading = true
        console.log(this.date.getDate())
        await this.setDiscountBlockDay({
          value: this.date.getDate()
        })
        this.loading = false
      }
    },
    async mounted() {
      this.loading = true
      // await this.getDiscountBlockDay()
      console.log(new Date())
      setTimeout(() => {
        this.date = this.getRandomDateAsDateObject(Number(this.discountBlockDay))
      }, 100)
      this.loading = false
      //this.date = new Date()
    }
  }
</script>

<style lang="scss">
  @import '../../styles/variables';

  .only-day-picker {
    .dp__calendar_header {
      display: none;
    }
  }
</style>
